@import './Calculator/display.scss';
@import './Calculator/_keypad.scss';
@import './Calculator/_calculator.scss';
@import './Calculator/_history.scss';
@import './Calculator/_control-panel.scss';

* {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

::-webkit-scrollbar {
    width: 13px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.438);
    background-clip: content-box;
}