.keypad {
    height: fit-content;
    width: fit-content;
    background-color: transparent;
    padding: 3px;

    &-row {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;

        button {
            font-size: 1.1rem;
            margin: 1px;
            overflow: hidden;
            height: 52px;
            width: 76px;
            border-radius: 0;
            color: #ffffff;
            border: none;
        
            &:hover {
                background-color: #818181;
            }
        
            img {
                width: 18px;
                height: auto;
            }
        }
    }

    .primary {
        background-color: rgba(0, 0, 0, 0.9);
    }
    
    .secondary {
        background-color: rgba(0, 0, 0, 0.3);
    }
}


