.history {
    position: relative;
    max-height: 457px;
    width: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0);

    button {
        background-color: transparent;
        border: none;
        color: rgba(255, 255, 255, 0.7);
        width: 100%;
        text-align: right;
        padding: 0.8rem 0.8rem;
        margin-left: calc(0.8rem +15px);
        font-size: 1rem;
        border-bottom: 1px solid rgba(27, 27, 27, 0.2);
    }

    &-item {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: ease-in-out 0.3s;

        &:hover {
            background-color: rgba(0, 0, 0, 0.25);
            cursor: pointer;
        }
    }

    &-trash {
        width: fit-content;

        img {
            width: 20px;
            height: auto;
            padding: 1rem;
            opacity: 0.3;
        }
    }
}

.opened {
    width: 220px;
}

.history .history-item > button {
    border-radius: 0;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.history-trash {
    cursor: pointer;
    left: 10px;
    position: absolute;
    right: 10px;
}
