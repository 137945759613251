.control-panel {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    
    button {
        border: none;
        width: 40px;
        height: 40px;
    }

    img {
        width: 20px;
        height: auto;
        padding: 1rem 0.5rem 0 0;
    }

    .open-history {
        background-color: transparent;
        cursor: pointer;
    }
}